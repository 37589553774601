<template>
  <div class="content-graphics-all">
    <div class="content-graphics-raw-materials">
      <div
        class="content-graphic-card-all"
        v-for="(item, i) in aGraphic.filter(
          (eGraphic) => eGraphic.sIdGraphic === 1
        )"
        :key="i"
      >
        <div class="content-header-graphic">
          <div class="content-title-graphic">
            <p class="title-graphic">{{ item.sTitle }}</p>
          </div>
        </div>
        <div class="content-graphic-card">
          <p class="text-left">{{ item.sNameTextLeft }}</p>
          <chart-component-global
            :series="item.series"
            :screenWidth="iWidthGraphic"
            :chartOptions="item.chartOptions"
            :sTypeChart="item.sTypeChart"
          />
          <p class="text-bottom">{{ item.sNameTextBottom }}</p>
        </div>
      </div>
    </div>

    <div class="content-graphics">
      <div
        class="content-graphic-card-all"
        v-for="(item, i) in aGraphic.filter(
          (eGraphic) => eGraphic.sIdGraphic !== 1
        )"
        :key="i"
      >
        <div class="content-header-graphic">
          <div class="content-title-graphic">
            <p class="title-graphic">{{ item.sTitle }}</p>
          </div>
        </div>
        <div class="content-graphic-card">
          <p class="text-left">{{ item.sNameTextLeft }}</p>
          <chart-component-global
            :series="item.series"
            :screenWidth="screenWidth"
            :chartOptions="item.chartOptions"
            :sTypeChart="item.sTypeChart"
          />
          <p class="text-bottom">{{ item.sNameTextBottom }}</p>
        </div>
        <!-- <div class="content-empty-graphic-card"> 
                    Sin datos que mostrar.
                </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    aGraphic: Array,
    bResizeGraphics: Boolean,
  },
  data() {
    return {
      screenWidth: 0,
      iWidthGraphic: 0,
      //#region aGraphic hardcore //
      // aGraphic: [
      //     {
      //         sTitle: "Variación entre igresos y egresos",
      //         sTypeChart: "area",
      //         series: [
      //             {
      //                 name: "Entradas",
      //                 data: [11, 322, 23, 43, 5],
      //             },
      //             {
      //                 name: "Salidas",
      //                 data: [13, 22, 3, 4, 345, 6, 67, 788,],
      //             },
      //         ],
      //         chartOptions: {
      //             chart: {
      //                 width: 400,
      //                 type: "area",
      //                 zoom: {
      //                     enabled: false,
      //                     type: "x",
      //                     autoScaleYaxis: false,
      //                     zoomedArea: {
      //                         fill: {
      //                             color: "#90CAF9",
      //                             opacity: 0.4,
      //                         },
      //                         stroke: {
      //                             color: "#0D47A1",
      //                             opacity: 0.4,
      //                             width: 1,
      //                         },
      //                     },
      //                 },
      //             },
      //             dataLabels: {
      //                 enabled: false,
      //             },
      //             stroke: {
      //                 curve: "smooth",
      //             },
      //             xaxis: {
      //                 type: "int",
      //                 categories: [],
      //             },
      //             yaxis: {
      //                 labels: {
      //                     formatter: (value) => {
      //                         return value + " Kg";
      //                     },
      //                 },
      //             },
      //             tooltip: {
      //                 y: {
      //                     formatter: function (y) {
      //                         if (typeof y !== "undefined") {
      //                             return y.toFixed(2) + " kg";
      //                         }
      //                         return y;
      //                     },
      //                     format: "kg",
      //                 },
      //                 z: {
      //                     formatter: undefined,
      //                 },
      //             },
      //         },
      //     },
      //     {
      //         sTitle: "Entradas y salidas de materias primas",
      //         sTypeChart: "bar",
      //         series: [
      //             {
      //                 name: "Entradas",
      //                 data: [11, 322, 23, 43, 5],
      //             },
      //             {
      //                 name: "Salidas",
      //                 data: [13, 22, 3, 4, 345, 6, 67, 788,],
      //             },
      //         ],
      //         chartOptions: {
      //             chart: {
      //                 width: 400,
      //                 type: "area",
      //                 zoom: {
      //                     enabled: false,
      //                     type: "x",
      //                     autoScaleYaxis: false,
      //                     zoomedArea: {
      //                         fill: {
      //                             color: "#90CAF9",
      //                             opacity: 0.4,
      //                         },
      //                         stroke: {
      //                             color: "#0D47A1",
      //                             opacity: 0.4,
      //                             width: 1,
      //                         },
      //                     },
      //                 },
      //             },
      //             dataLabels: {
      //                 enabled: false,
      //             },
      //             stroke: {
      //                 curve: "smooth",
      //             },
      //             xaxis: {
      //                 type: "int",
      //                 categories: [],
      //             },
      //             yaxis: {
      //                 labels: {
      //                     formatter: (value) => {
      //                         return value + " Kg";
      //                     },
      //                 },
      //             },
      //             tooltip: {
      //                 y: {
      //                     formatter: function (y) {
      //                         if (typeof y !== "undefined") {
      //                             return y.toFixed(2) + " kg";
      //                         }
      //                         return y;
      //                     },
      //                     format: "kg",
      //                 },
      //                 z: {
      //                     formatter: undefined,
      //                 },
      //             },
      //         },
      //     }
      // ]
      //#endregion  hardcore //
      bIn: "in",
      bOut: "out",
      sEntryOrEgress: "",
    };
  },
  beforeMount() {
    // this.widthChart();
  },
  updated() {
    if (window.innerWidth > 960) {
      this.widthChart();
    }
  },
  created() {
    window.addEventListener("resize", this.widthChart);
    this.widthChart();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.widthChart);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      if (window.innerWidth > 960) {
        this.widthChart();
      } else {
        this.screenWidth = window.innerWidth - 65;
        this.iWidthGraphic = window.innerWidth - 65;
      }
    },
    widthChart() {
      // this.$nextTick((e) => {
      if (this.aGraphic.length > 0) {
        if (this.bSidebar) {
          //   this.screenWidth = (window.innerWidth - 520) / this.aGraphic.length;
          this.screenWidth = (window.innerWidth - 520) / 2;
          this.iWidthGraphic = window.innerWidth - 520;
        } else {
          //   this.screenWidth = (window.innerWidth - 300) / this.aGraphic.length;
          this.screenWidth = (window.innerWidth - 300) / 2;
          this.iWidthGraphic = window.innerWidth -240;
        }
      }
      // });
    },
  },
  computed: {
    bSidebar() {
      return this.$store.state.bSidebar;
    },
  },
  watch: {
    sEntryOrEgress() {},
    bSidebar() {
      this.widthChart();
    },
    bResizeGraphics() {
      this.screenWidth = this.screenWidth - 0.0000000002;
      this.iWidthGraphic = this.iWidthGraphic - 0.0000000002;
    },
  },
};
</script>

<style scoped>
.content-graphics-raw-materials{
    margin-bottom: 20px;
}
.content-graphics {
  display: flex;
}

.text-left {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  left: 5px;
  bottom: 0;
  top: 30%;
  writing-mode: vertical-rl;
  text-orientation: upright;
  position: absolute;
  letter-spacing: 14px;
}

.text-bottom {
  color: var(--primary-color-color-title-information);
  font-family: "pop-Bold";
  font-size: 14px;
  text-align: center;
  left: 0%;
  right: 0%;
  bottom: -10px;
  position: absolute;
  letter-spacing: 14px;
}

.content-graphic-card-all {
  width: 100%;
  margin-right: 10px;
}

.content-header-graphic {
  display: flex;
  margin-bottom: 15px;
  align-items: center;
}

.content-title-graphic {
  width: 100%;
}

.title-graphic {
  font-family: "pop-Semibold";
  font-size: 13px;
  color: var(--primary-color-color-title-information);
  margin-bottom: 0px;
}

.content-graphic-card {
  /* width: 100%; */
  position: relative;
  height: 440px;
  padding: 10px 20px 10px 20px;
  border-radius: 10px;
  align-items: center;
  background-color: var(--primary-color-background-table) !important;
}

.content-information-bottom {
  text-align: center;
  margin-top: -20px;
}

.text-information-bottom {
  font-family: "pop-Semibold";
  font-size: 15px;
  color: var(--primary-color-text);
  letter-spacing: 5px;
}

.content-infromation-color {
  display: flex;
  justify-content: center;
  /* margin-left: 60px; */
}

.content-name-color-graphic {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.content-color-graphic {
  height: 15px;
  width: 15px;
  border-radius: 20px;
}

.text-name-color-graphic {
  margin-right: 10px;
  text-align: center;
  color: var(--primary-color-text);
  font-family: "pop-Regular";
  font-size: 12px;
}

/*#region MODO RESPONSIVO */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-graphics {
    display: block;
    margin-right: 10px;
    /* margin-left: 10px; */
  }

  .content-graphic-card-all {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 15px;
  }

  .content-graphic-card {
    width: 100%;
    height: auto;
    padding: 0px 0px 0px 0px;
    border-radius: 10px;
    align-items: center;
    background-color: var(--primary-color-background-table) !important;
  }

  .content-information-bottom {
    text-align: center;
    margin-top: -20px;
  }

  .content-infromation-color {
    display: flex;
    justify-content: center;
    padding: 10px;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .text-left {
    color: var(--primary-color-color-title-information);
    font-family: "pop-Bold";
    font-size: 14px;
    left: 0px;
    bottom: 0;
    top: 30%;
    writing-mode: vertical-rl;
    text-orientation: upright;
    position: absolute;
    letter-spacing: 14px;
  }

  .text-bottom {
    color: var(--primary-color-color-title-information);
    font-family: "pop-Bold";
    font-size: 14px;
    text-align: center;
    left: 0%;
    right: 0%;
    bottom: -14px;
    position: absolute;
    letter-spacing: 14px;
  }

  .content-graphics {
    display: block;
    margin-right: 10px;
    margin-left: 10px;
  }

  .content-graphic-card-all {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    margin-bottom: 15px;
  }

  .content-graphic-card {
    width: 100%;
    height: auto;
    padding: 0px 0px 0px 0px;
    border-radius: 10px;
    align-items: center;
    background-color: var(--primary-color-background-table) !important;
  }

  .content-information-bottom {
    text-align: center;
    margin-top: -10px;
  }

  .content-infromation-color {
    display: flex;
    justify-content: center;
    padding: 10px;
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO */
</style>
